const fiftyTokenPackage = ["4184", "4185"]

const hundRedTokenPackageMobileShortcode = "4154"
const hundRedTokenPackageDesktopShortcode = "4137"

type PurchasedProduct = {
    product_id: string
    tokens: number
    cost: string
}

export const getProductForAutoRefill = (
    purchasedProduct: PurchasedProduct,
    isMobile: boolean,
): PurchasedProduct => {
    const { product_id } = purchasedProduct
    const splittedProductId = product_id.split("-") // xx-xxx-xxxx

    if (splittedProductId.length < 3) {
        return purchasedProduct
    }

    const shortCode = splittedProductId[2]

    if (fiftyTokenPackage.includes(shortCode)) {
        // swap 50 token package with 100 token package for autorefill default
        // since 50 token package is not available for autorefill

        // looks like main prefix not really change but just to be safe, we will take it from passed product_id
        const fullProductCode = splittedProductId
            .slice(0, -1)
            .concat(
                isMobile
                    ? hundRedTokenPackageMobileShortcode
                    : hundRedTokenPackageDesktopShortcode,
            )
            .join("-")
        return {
            product_id: fullProductCode,
            tokens: 100,
            cost: "10.99",
        }
    }

    // for everyting else, just return the same product
    return purchasedProduct
}
