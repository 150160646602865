import { useEffect, useState } from "react"
import { t } from "@lingui/macro"

import {
    useCreateAutoRefilSettingsMutation,
    useLazyGetAutoRefilSettingsQuery,
    useUpdateAutoRefilSettingsMutation,
} from "../../store/purchasePage/purchasePageSlice"
import { showToast } from "../../utils/sharedToast"
import type { AutoRefillTypeResponse } from "../../store/purchasePage/purchasePageSlice"

type UseAutoRefillToggle = {
    isActive: boolean
    isLoading: boolean
    isSaving: boolean
    handleAutoRefillChange: (val: boolean, productId?: string) => Promise<void>
    initialAutoRefillSettings: AutoRefillTypeResponse | undefined
    autoRefillSettings: AutoRefillTypeResponse | undefined
}
type UseAutoRefillToggleProps = {
    skip: boolean
    toastContainer?: string
}
export function useAutoRefillToggle({
    skip,
    toastContainer,
}: UseAutoRefillToggleProps): UseAutoRefillToggle {
    const [isActive, setIsActive] = useState(false)
    const [hadAutoRefillSettings, setHadAutoRefillSettings] = useState(false)
    const [initialAutoRefillSettings, setInitialAutoRefillSettings] =
        useState<AutoRefillTypeResponse>()
    const [autoRefillSettings, setAutoRefillSettings] =
        useState<AutoRefillTypeResponse>()

    const [getAutoRefillSettings, { isLoading }] =
        useLazyGetAutoRefilSettingsQuery()

    const [createAutoRefillSettings, { isLoading: isCreating }] =
        useCreateAutoRefilSettingsMutation()
    const [updateAutoRefillSettings, { isLoading: isUpdating }] =
        useUpdateAutoRefilSettingsMutation()

    const handleAutoRefillToggleClick = async (
        val: boolean,
        productId?: string,
    ) => {
        const funcToUse = hadAutoRefillSettings
            ? updateAutoRefillSettings
            : createAutoRefillSettings

        await funcToUse({ is_active: val, product_code: productId })
            .unwrap()
            .then((res) => {
                if (!res.success) {
                    showToast({
                        containerId: toastContainer,
                        message: t`Auto refill may not be available for your last purchase.`,
                    })
                    return
                }
                if (!hadAutoRefillSettings) {
                    setHadAutoRefillSettings(true)
                }

                showToast({
                    containerId: toastContainer,
                    message: val
                        ? t`Auto Refill enabled`
                        : t`Auto Refill disabled`,
                })
                setIsActive(val)
                setAutoRefillSettings(res)
            })
            .catch(() => {
                showToast({
                    containerId: toastContainer,
                    message: t`Failed to update Auto Refill settings`,
                })
            })
    }

    useEffect(() => {
        if (skip) {
            return
        }
        void getAutoRefillSettings()
            .unwrap()
            .then((res) => {
                const isAutoRefillEnabled =
                    Boolean(res.success) && Boolean(res.is_active)
                setIsActive(isAutoRefillEnabled)
                setHadAutoRefillSettings(Boolean(res.plan?.product_id))
                setAutoRefillSettings(res)
                if (initialAutoRefillSettings === undefined) {
                    setInitialAutoRefillSettings(res)
                }
            })
            .catch(() => {
                setIsActive(false)
                setHadAutoRefillSettings(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAutoRefillSettings, skip])

    return {
        isActive,
        isLoading: isLoading,
        isSaving: isCreating || isUpdating,
        handleAutoRefillChange: handleAutoRefillToggleClick,
        autoRefillSettings,
        initialAutoRefillSettings,
    }
}
