import { useEffect } from "react"
import { toast, ToastContainer } from "react-toastify"
import { AppContextProvider, useAppContext } from "../../../../hooks/appContext"
import { FlaggedI18nProvider } from "../../../../i18n"
import { mergeClasses } from "../../../../utils/css"
import { addPageAction } from "../../../../utils/newrelic"
import {
    COOLDOWN_DURATION,
    getCooldownTime,
    isPageActionNeeded,
    setCooldownTime,
    ToastContainers,
    TOASTS_WITH_COOLDOWN,
    ToastTypes,
} from "../../../../utils/sharedToast"
import { CheckmarkCircleGreen } from "../../atoms/Icons/Chat"
import { CommonLowBalanceToast } from "./CommonLowBalanceToast"
import { PrivateLowBalanceToast } from "./PrivateLowBalanceToast"
import { SuccessAutoRefillToast } from "./SuccessAutoRefillToast"
import type { MainToastType } from "../../../../utils/sharedToast"
import type { ToastContainerProps, ToastOptions } from "react-toastify"
import "./SharedToastContainer.scss"

interface CustomEventDetails extends ToastContainerProps {
    message: string
    type: MainToastType
    containerId?: string
    onClick: () => void
    numTokens?: string
    className?: string
    timeRemaining?: number
}

interface SharedToastContainerProps extends ToastContainerProps {
    containerId: string
    className?: string
    style?: React.CSSProperties
}

export const SharedToastContainer = ({
    containerId,
    className = "",
    style,
    ...props
}: SharedToastContainerProps) => {
    const isMobile = useAppContext().context.is_mobile
    useEffect(() => {
        // eslint-disable-next-line complexity
        const handleShowToast = (event: CustomEvent<CustomEventDetails>) => {
            const {
                message,
                type = "success",
                containerId = ToastContainers.Global,
                onClick,
                numTokens,
                timeRemaining,
                className: classNameProp,
                ...other
            } = event.detail

            // prevent multitab toasts
            if (document.hidden) {
                return
            }
            const now = Date.now()
            // Check cooldown for selected toasts
            if (TOASTS_WITH_COOLDOWN.has(type)) {
                const lastShown = getCooldownTime(type)
                if (now - lastShown < COOLDOWN_DURATION) {
                    return
                }
            }

            const isFullScreen = classNameProp?.includes("fullscreen")
            const commonToastProps: ToastOptions = {
                containerId,
                className: mergeClasses(type, classNameProp),
                ...(isPageActionNeeded(type)
                    ? {
                          onClose: () => {
                              addPageAction("ToastClosed", { type })
                          },
                          onOpen: () => {
                              addPageAction("ToastOpened", { type })
                          },
                      }
                    : {}),
                ...other,
                onClose: () => {
                    if (TOASTS_WITH_COOLDOWN.has(type)) {
                        setCooldownTime(type)
                    }
                },
            }

            if (type === ToastTypes.SuccessAutorefill) {
                toast(<SuccessAutoRefillToast message={message} />, {
                    ...commonToastProps,
                    containerId: isFullScreen
                        ? ToastContainers.VideoPlayer
                        : ToastContainers.Global,
                    type: "success",
                    autoClose: 4000,
                })
                return
            }

            if (type === ToastTypes.ChatursafeFilterUpdated) {
                toast(message, {
                    ...commonToastProps,
                    containerId: ToastContainers.ChatursafeFilterContainer,
                    type: "success",
                    icon: <CheckmarkCircleGreen />,
                })
                return
            }

            if (type === ToastTypes.LowBalancePrivate) {
                if (toast.isActive(ToastTypes.LowBalanceCommon)) {
                    toast.dismiss(ToastTypes.LowBalanceCommon)
                }

                const toastOptions: ToastOptions = {
                    ...commonToastProps,
                    toastId: ToastTypes.LowBalancePrivate,
                    type: "success",
                    containerId: isFullScreen
                        ? ToastContainers.VideoPlayer
                        : isMobile
                          ? ToastContainers.Global
                          : ToastContainers.VideoPlayer,
                    autoClose: false,
                }
                if (toast.isActive(ToastTypes.LowBalancePrivate)) {
                    toast.update(ToastTypes.LowBalancePrivate, {
                        render: (
                            <PrivateLowBalanceToast
                                key={now}
                                openPuchasePage={onClick}
                                timeLeftInSec={timeRemaining ?? 0}
                            />
                        ),
                        ...toastOptions,
                    })
                    return
                }
                toast(
                    <PrivateLowBalanceToast
                        openPuchasePage={onClick}
                        timeLeftInSec={timeRemaining ?? 0}
                    />,
                    toastOptions,
                )
                return
            }

            if (type === ToastTypes.LowBalanceCommon) {
                if (toast.isActive(ToastTypes.LowBalancePrivate)) {
                    return
                }

                toast(
                    <CommonLowBalanceToast
                        openPuchasePage={onClick}
                        numTokens={numTokens ?? "0"}
                    />,
                    {
                        ...commonToastProps,
                        toastId: ToastTypes.LowBalanceCommon,
                        containerId: isFullScreen
                            ? ToastContainers.VideoPlayer
                            : !isMobile
                              ? ToastContainers.PurchaseContainer
                              : ToastContainers.Global,
                        autoClose: false,
                    },
                )
                return
            }
            // Use react-toastify to display the toast
            toast(message, {
                ...commonToastProps,
                type,
                // if we pass containerId, it will be used, otherwise we will use the default container
                containerId:
                    containerId ||
                    (isFullScreen
                        ? ToastContainers.VideoPlayer
                        : ToastContainers.Global),
            })
        }

        document.addEventListener(
            containerId || ToastContainers.Global,
            handleShowToast as EventListener,
        )

        return () => {
            document.removeEventListener(
                containerId || ToastContainers.Global,
                handleShowToast as EventListener,
            )
        }
    }, [containerId])

    return (
        <ToastContainer
            style={style}
            position="top-center"
            containerId={containerId}
            hideProgressBar
            autoClose={3000}
            pauseOnFocusLoss={false}
            {...props}
            className={mergeClasses(
                "SharedToastContainer",
                isMobile ? "mobile" : "desktop",
                className,
            )}
        />
    )
}

export const WrappedSharedToastContainer = (
    props: SharedToastContainerProps,
) => {
    return (
        <AppContextProvider context={window as any}>
            <FlaggedI18nProvider>
                <SharedToastContainer {...props} />
            </FlaggedI18nProvider>
        </AppContextProvider>
    )
}
