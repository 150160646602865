import { createRoot } from "react-dom/client"
import { toast } from "react-toastify"
import { WrappedSharedToastContainer } from "../components/common/organisms/SharedToastContainer"
import { performToggleIsActive } from "../hooks/appContext"
import type { ToastOptions, TypeOptions } from "react-toastify"

declare global {
    interface Window {
        showToast: (args: { message?: string } & ToastOptions) => void
        dismissToast: (toastId: string) => void
    }
}

type ReverseMap<T> = T[keyof T]
type CustomToastType = ReverseMap<typeof ToastTypes>
export type MainToastType = TypeOptions | CustomToastType

export enum ToastContainers {
    Global = "global-toast",
    VideoPlayer = "video-player-toast-container",
    PurchaseContainer = "purchase-toast-container",
    ChatursafeFilterContainer = "chatursafe-filter-toast-container",
}

export enum ToastTypes {
    LowBalanceCommon = "low-balance-common",
    LowBalancePrivate = "low-balance-private",
    SuccessAutorefill = "success-autorefill",
    ChatursafeFilterUpdated = "chatursafe-filter-updated",
}

const toastTypesWithPageActions: MainToastType[] = [
    ToastTypes.LowBalanceCommon,
    ToastTypes.LowBalancePrivate,
]

export const COOLDOWN_DURATION = 60 * 5 * 1000 // 5 min

export const TOASTS_WITH_COOLDOWN = new Set<MainToastType>([
    ToastTypes.LowBalancePrivate,
])

export const getCooldownTime = (type: string) => {
    const lastShown = localStorage.getItem(`toastCooldown_${type}`)
    return lastShown ? parseInt(lastShown, 10) : 0
}

export const setCooldownTime = (type: string) => {
    localStorage.setItem(`toastCooldown_${type}`, Date.now().toString())
}

export const isPageActionNeeded = (type: MainToastType) => {
    return toastTypesWithPageActions.includes(type)
}

export const showToast = (args: { message?: string } & ToastOptions) => {
    const flagActive =
        performToggleIsActive("TknRflTopUp") || performToggleIsActive("Chtrsf")
    if (window.showToast === undefined || !flagActive) {
        return
    }
    window.showToast({ ...args, type: args.type ?? "success" })
}

export const injectToastContainer = () => {
    if (
        !performToggleIsActive("TknRflTopUp") &&
        !performToggleIsActive("Chtrsf")
    ) {
        return
    }
    // Global toast trigger
    if (window.showToast === undefined) {
        window["showToast"] = (args) => {
            const event = new CustomEvent(
                (args.containerId as string) ?? "global-toast",
                {
                    detail: args,
                },
            )
            document.dispatchEvent(event)
        }
    }

    if (window.dismissToast === undefined) {
        window["dismissToast"] = (toastId: string) => {
            if (toast.isActive(toastId)) {
                toast.dismiss(toastId)
            }
        }
    }

    const containerId = "global-toast"
    let container = document.getElementById(containerId)

    if (!container) {
        container = document.createElement("div")
        container.id = containerId
        document.body.appendChild(container)
    }

    const root = createRoot(container)
    root.render(
        <WrappedSharedToastContainer containerId="global-toast" limit={2} />,
    )
}
