import { t } from "@lingui/macro"
import { useAppContext } from "../../../../hooks/appContext"
import { Sizes } from "../../../../interfaces/sizing"
import { Link } from "../../atoms/Link"
import { Typography } from "../../atoms/Typography"
import "./SuccessAutoRefillToast.scss"

export const SuccessAutoRefillToast = ({ message }: { message: string }) => {
    const userName = useAppContext()?.context?.logged_in_user?.username
    const isMobile = useAppContext()?.context?.is_mobile
    return (
        <div className="success-autorefill-toast">
            <Typography size={Sizes.smpx}>{message}</Typography>
            {!isMobile && (
                <div className="success-autorefill-toast__manage">
                    <Link
                        href={`/p/${userName}/?tab=settings&from_autorefill_link=1`}
                        target="_blank"
                    >
                        {t`Manage Auto Refill`}
                    </Link>
                </div>
            )}
        </div>
    )
}
