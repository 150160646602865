import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { createSlice } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../utils/myFetch"
import type { Message, Thread } from "../components/messaging/types"
import type { IUserInfo } from "@multimediallc/web-utils/types"
import type { PayloadAction } from "@reduxjs/toolkit"

declare global {
    interface Window {
        PRODUCTION: boolean
    }
}

interface MessageResponse {
    messages: Message[]
    num_unread: number
    has_more: boolean
}

export interface ThreadsResponse {
    threads: Thread[]
    has_more: boolean
    has_conversation_features: boolean
}

interface ThreadsQueryParams {
    offset: number
    limit: number
}

export const DM_THREAD_PAGE_LIMIT = 20

export const DEFAULT_THREAD_PARAMS: ThreadsQueryParams = {
    offset: 0,
    limit: DM_THREAD_PAGE_LIMIT,
}

interface MessagingState {
    conversationListScroll: number
}

const initialState: MessagingState = {
    conversationListScroll: 0,
}

export const messagingSlice = createSlice({
    name: "messaging",
    initialState,
    reducers: {
        setConversationListScroll: (state, action: PayloadAction<number>) => {
            state.conversationListScroll = action.payload
        },
    },
})

export const { setConversationListScroll } = messagingSlice.actions
export default messagingSlice.reducer

interface PublishMessageRequest {
    message: string
    to_user: string
    from_user: string
}

interface PublishMessageResponse {
    status: string
}

const transformUserInfo = (user: any): IUserInfo => ({
    username: user.username,
    isBroadcaster: user.is_broadcaster,
    inFanclub: user.in_fanclub,
    isFollowing: user.is_following,
    hasTokens: user.has_tokens,
    isMod: user.is_mod,
    tippedRecently: user.tipped_recently,
    tippedALotRecently: user.tipped_alot_recently,
    tippedTonsRecently: user.tipped_tons_recently,
    gender: user.gender,
})

export const messagingApi = createApi({
    reducerPath: "messagingApi",
    tagTypes: ["History", "Threads"],
    baseQuery: cbBaseQuery({
        baseUrl: "/api/messaging/",
    }),
    endpoints: (builder) => ({
        getUnread: builder.query<any, void>({
            query: () => ({
                url: "unread/",
                method: "GET",
            }),
        }),
        getThreads: builder.query<
            ThreadsResponse,
            { offset: number; limit: number; search_username?: string }
        >({
            query: ({ offset, limit, search_username }) => ({
                url: search_username
                    ? `threads/?offset=${offset}&limit=${limit}&search_username=${search_username}`
                    : `threads/?offset=${offset}&limit=${limit}`,
                method: "GET",
            }),
            transformResponse: (response: any) => {
                if (!response) return response

                const transformed = { ...response }
                if (transformed.threads) {
                    transformed.threads = transformed.threads.map(
                        (thread: any) => ({
                            ...thread,
                            other_user: thread.other_user
                                ? transformUserInfo(thread.other_user)
                                : undefined,
                        }),
                    )
                }
                return transformed
            },
            providesTags: () => [{ type: "Threads", id: "LIST" }],
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems, { arg }) => {
                if (arg.offset === 0) {
                    return newItems
                }

                const mergedThreads = [...currentCache.threads]

                newItems.threads.forEach((newThread) => {
                    const existingIndex = mergedThreads.findIndex(
                        (thread) =>
                            thread?.other_user?.username ===
                            newThread?.other_user?.username,
                    )
                    if (existingIndex !== -1) {
                        mergedThreads[existingIndex] = newThread
                    } else {
                        mergedThreads.push(newThread)
                    }
                })

                return {
                    threads: mergedThreads,
                    has_more: newItems.has_more,
                    has_conversation_features:
                        newItems.has_conversation_features,
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.offset !== previousArg?.offset
            },
        }),
        publishMessage: builder.mutation<
            PublishMessageResponse,
            PublishMessageRequest
        >({
            query: (data) => ({
                url: "publish/",
                method: "POST",
                data: {
                    csrfmiddlewaretoken: getCookieOrEmptyString("csrftoken"),
                    message: JSON.stringify({ m: data.message }),
                    to_user: data.to_user,
                    from_user: data.from_user,
                },
            }),
        }),
        getHistory: builder.query<
            MessageResponse,
            { username: string; offset?: string }
        >({
            query: ({ username, offset }) => ({
                url: `history/${username}/${offset ? `?offset=${offset}` : ""}`,
                method: "GET",
            }),
            transformResponse: (response: any) => {
                if (!response) return response

                const transformed = { ...response }
                if (transformed.messages) {
                    transformed.messages = transformed.messages.map(
                        (message: any) => ({
                            ...message,
                            from_user: message.from_user
                                ? transformUserInfo(message.from_user)
                                : undefined,
                        }),
                    )
                }
                return transformed
            },
            serializeQueryArgs: ({ queryArgs }) => {
                return queryArgs.username
            },
            merge: (currentCache, newItems, { arg }) => {
                if (newItems.messages === undefined) {
                    newItems.messages = []
                }

                if (arg.offset) {
                    return {
                        messages: [
                            ...newItems.messages,
                            ...currentCache.messages,
                        ],
                        num_unread: currentCache.num_unread,
                        has_more: newItems.has_more,
                    }
                }

                // Preserve temporary tip messages when refetching without offset
                const temporaryTipMessages =
                    currentCache?.messages?.filter(
                        (msg) =>
                            msg?.is_temporary_tip_message &&
                            !newItems.messages.find((m) => m.i === msg.i),
                    ) || []

                const allMessages = [
                    ...newItems.messages,
                    ...temporaryTipMessages,
                ].sort((a, b) => a.created_at - b.created_at)

                return {
                    messages: allMessages,
                    num_unread: newItems.num_unread,
                    has_more: newItems.has_more,
                }
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                return currentArg?.offset !== previousArg?.offset
            },
            providesTags: (result, error, { username }) => [
                { type: "History" as const, id: username },
            ],
        }),
        getProfile: builder.query<any, string>({
            query: (otherUser) => ({
                url: `profile/${otherUser}/`,
                method: "GET",
            }),
            transformResponse: (response: any) => {
                if (!response) return response

                const transformed = { ...response }
                if (transformed.user) {
                    transformed.user = transformUserInfo(transformed.user)
                }
                if (transformed.sitewide_user) {
                    transformed.sitewide_user = transformUserInfo(
                        transformed.sitewide_user,
                    )
                }
                return transformed
            },
        }),
        markAsRead: builder.mutation<void, string>({
            query: (username) => ({
                url: "last-seen/",
                method: "POST",
                data: {
                    from_usernames: username,
                },
            }),
            async onQueryStarted(username, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateThreadUnread(username, 0))

                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        typingIndicator: builder.mutation<void, { to_username: string }>({
            query: (data) => ({
                url: "typing/",
                method: "POST",
                data: {
                    csrfmiddlewaretoken: getCookieOrEmptyString("csrftoken"),
                    to_username: data.to_username,
                },
            }),
        }),
        deleteConversation: builder.mutation<void, { to_username: string }>({
            query: (data) => ({
                url: "delete-conversation/",
                method: "POST",
                data: {
                    csrfmiddlewaretoken: getCookieOrEmptyString("csrftoken"),
                    to_username: data.to_username,
                },
            }),
        }),
    }),
})

type UpdateQueryDataResult = ReturnType<
    typeof messagingApi.util.updateQueryData
>

export const upsertThread = (
    newThread: Thread,
    isFromMe: boolean,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getThreads",
        DEFAULT_THREAD_PARAMS,
        (draft) => {
            const existingThreadIdx = draft.threads.findIndex(
                (thread) =>
                    thread?.other_user?.username ===
                    newThread?.other_user?.username,
            )
            if (existingThreadIdx !== -1) {
                const [existingThread] = draft.threads.splice(
                    existingThreadIdx,
                    1,
                )
                newThread.num_unread = isFromMe
                    ? existingThread?.num_unread || 0
                    : (existingThread?.num_unread || 0) + 1
            }
            draft.threads.unshift(newThread)
            return draft
        },
    )
}

export const updateThreadUnread = (
    username: string,
    numUnread: number,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getThreads",
        DEFAULT_THREAD_PARAMS,
        (draft) => {
            const thread = draft.threads.find(
                (t) => t?.other_user?.username === username,
            )
            if (thread) {
                thread.num_unread = numUnread
            }
            return draft
        },
    )
}

export const addMessageToHistory = (
    username: string,
    newMessage: Message,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getHistory",
        { username },
        (draft) => {
            if (draft.messages === undefined) {
                draft.messages = [newMessage]
            } else {
                draft.messages.push(newMessage)
            }
            return draft
        },
    )
}

export const removeThread = ({
    username,
}: {
    username: string
}): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getThreads",
        DEFAULT_THREAD_PARAMS,
        (draft) => {
            const index = draft.threads.findIndex(
                (thread) => thread?.other_user?.username === username,
            )
            if (index >= 0) {
                draft.threads.splice(index, 1)
            }
            return draft
        },
    )
}

export const {
    useGetUnreadQuery,
    useGetThreadsQuery,
    useLazyGetThreadsQuery,
    usePublishMessageMutation,
    useGetHistoryQuery,
    useLazyGetHistoryQuery,
    useGetProfileQuery,
    useMarkAsReadMutation,
    useTypingIndicatorMutation,
    useDeleteConversationMutation,
} = messagingApi
