import type { ComponentPropsWithoutRef } from "react"
import "./PurchaseSuccessPage.scss"
import { t } from "@lingui/macro"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Link, Typography } from "../../common"
import { TokenPurchaseSuccessDarkMode } from "../../common/atoms/Icons/Purchase"
import TokenPurchaseSuccess from "../../common/atoms/Icons/Purchase/TokenPurchaseSuccess"
import { SharedToastContainer } from "../../common/organisms/SharedToastContainer"
import { AutoRefill } from "../AutoRefill"
import { getProductForAutoRefill } from "./getProductForAutorefill"

interface PurchaseSuccessPageProps extends ComponentPropsWithoutRef<"div"> {
    tokenAmount: number
    isSupporterPlan: boolean
    tokenPrice?: string
    returnUrl?: string
    returnToUrlName?: string
    productId: string
}

export function PurchaseSuccessPage({
    tokenAmount,
    isSupporterPlan,
    tokenPrice,
    returnUrl,
    returnToUrlName,
    productId,
    ...props
}: PurchaseSuccessPageProps) {
    const showAutoRefill = !!tokenPrice && !isSupporterPlan
    const isMobile = useAppContext()?.context?.is_mobile

    const purchasedProduct = getProductForAutoRefill(
        {
            product_id: productId,
            tokens: tokenAmount,
            cost: tokenPrice ?? "",
        },
        isMobile,
    )
    const isDarkMode = document.body.classList.contains("darkmode")
    return (
        <div className="PurchaseSuccessPage" {...props}>
            <div className="PurchaseSuccessPage__icon">
                {isDarkMode ? (
                    <TokenPurchaseSuccessDarkMode />
                ) : (
                    <TokenPurchaseSuccess />
                )}
            </div>
            <Typography
                weight={Weights.bold}
                size={isMobile ? Sizes.lgpx : Sizes.mdpx}
                className="PurchaseSuccessPage__title"
            >{t`Purchase Successful!`}</Typography>
            <Typography
                size={isMobile ? Sizes.smpx : Sizes.xspx}
                className="PurchaseSuccessPage__message"
            >
                {isSupporterPlan
                    ? t`Supporter was added to your account.`
                    : tokenAmount
                      ? t`${tokenAmount} tokens added. They’ll appear in your account within 10 minutes.`
                      : ""}
            </Typography>
            {showAutoRefill && (
                <AutoRefill purchasedProduct={purchasedProduct} />
            )}
            {returnUrl && (
                <div className="PurchaseSuccessPage__link">
                    <Link href={returnUrl}>
                        {`Return to ${returnToUrlName || returnUrl}`}
                    </Link>
                </div>
            )}
            <SharedToastContainer
                containerId="purchase-success-toast"
                className="purchase-success-toast"
                limit={2}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                autoClose={1000}
            />
        </div>
    )
}
