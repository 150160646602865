import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface FollowersState {
    followersMap: Record<string, boolean>
}

const initialState: FollowersState = {
    followersMap: {},
}

export const followersSlice = createSlice({
    name: "followers",
    initialState: initialState,
    reducers: {
        setFollowers: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach((username) => {
                state.followersMap[username] = true
            })
        },
        userFollowed: (state, action: PayloadAction<string>) => {
            const username = action.payload
            state.followersMap[username] = true
        },
        userUnfollowed: (state, action: PayloadAction<string>) => {
            const username = action.payload
            state.followersMap[username] = false
        },
    },
})

export const { setFollowers, userFollowed, userUnfollowed } =
    followersSlice.actions

export const getFollowers = (state: {
    followers: FollowersState
}): Record<string, boolean> => state.followers.followersMap

export default followersSlice.reducer
