import type { ComponentPropsWithoutRef } from "react"
import { useCallback, useRef } from "react"
import { createPortal } from "react-dom"
import { useTooltip } from "../../../../hooks/useTooltip"
import { mergeClasses } from "../../../../utils/css"
import { FloatingTooltip } from "../../atoms/FloatingTooltip/FloatingTooltip"
import { Info, InfoOutline } from "../../atoms/Icons/Others"
import "./InfoIconTooltip.scss"

interface InfoIconTooltipProps extends ComponentPropsWithoutRef<"div"> {
    text?: string
    tooltipClassName?: string
    useDivot?: boolean
    strokedIcon?: boolean
}

export function InfoIconTooltip({
    text,
    tooltipClassName,
    className,
    useDivot,
    strokedIcon,
    children,
    ...props
}: InfoIconTooltipProps) {
    const ref = useRef<SVGSVGElement>(null)
    const { isOpen } = useTooltip(ref)

    const getIconBounds = useCallback((): DOMRect | undefined => {
        return ref.current?.getBoundingClientRect()
    }, [])

    return (
        <div
            className={mergeClasses("cb-info-icon-tooltip", className)}
            {...props}
        >
            {strokedIcon ? <InfoOutline ref={ref} /> : <Info ref={ref} />}
            {isOpen &&
                createPortal(
                    // need createPortal to break out of draggable canvas z-index
                    <FloatingTooltip
                        getParentBounds={getIconBounds}
                        text={text}
                        className={mergeClasses(
                            "cb-info-icon-tooltip__ttcontent",
                            tooltipClassName,
                        )}
                        useDivot={useDivot}
                    >
                        {children}
                    </FloatingTooltip>,
                    document.body,
                )}
        </div>
    )
}
