import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../utils/myFetch"

export const ignoredUsersApi = createApi({
    reducerPath: "ignoredUsersApi",
    baseQuery: cbBaseQuery({
        baseUrl: "/api/ts/chat/",
    }),
    tagTypes: ["Ignore"],
    endpoints: (builder) => ({
        getIgnoredUsers: builder.query<{ users: string[] }, void>({
            query: () => ({
                url: "ignored-users/",
                method: "GET",
            }),
            providesTags: ["Ignore"],
        }),
        ignore: builder.mutation<void, { username: string }>({
            query: ({ username }) => ({
                url: "ignored-users/",
                method: "POST",
                data: {
                    username,
                },
            }),
            invalidatesTags: ["Ignore"],
        }),
        unignore: builder.mutation<void, { username: string }>({
            query: ({ username }) => ({
                url: `ignored-users/?username=${username}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Ignore"],
        }),
    }),
})

type UpdateQueryDataResult = ReturnType<
    typeof ignoredUsersApi.util.updateQueryData
>

export const addIgnoreUser = (username: string): UpdateQueryDataResult => {
    return ignoredUsersApi.util.updateQueryData(
        "getIgnoredUsers",
        undefined,
        (draft) => {
            if (!draft.users.includes(username)) {
                draft.users.push(username)
            }
        },
    )
}

export const removeIgnoreUser = (username: string): UpdateQueryDataResult => {
    return ignoredUsersApi.util.updateQueryData(
        "getIgnoredUsers",
        undefined,
        (draft) => {
            const index = draft.users.indexOf(username)

            if (index >= 0) {
                draft.users.splice(index, 1)
            }
        },
    )
}

export const {
    useGetIgnoredUsersQuery,
    useIgnoreMutation,
    useUnignoreMutation,
} = ignoredUsersApi
