import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgInfoOutline = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        fill="none"
        viewBox="0 0 16 16"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#A7A7A7"
            d="M7.333 6h1.334V4.667H7.333M8 13.334A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.334m0-12a6.666 6.666 0 1 0 0 13.332A6.666 6.666 0 0 0 8 1.334m-.667 10h1.334v-4H7.333z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgInfoOutline)
const Memo = memo(ForwardRef)
export default Memo
