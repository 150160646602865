import { createSlice } from "@reduxjs/toolkit"
import type { IStatusUpdate } from "@multimediallc/web-utils"

export interface BroadcastState {
    streamStatus?: IStatusUpdate
}

const initialState: BroadcastState = {
    streamStatus: undefined,
}

export const broadcastSlice = createSlice({
    name: "broadcast",
    initialState: initialState,
    reducers: {
        updateStreamStatus: (state, action) => {
            state.streamStatus = action.payload
        },
    },
})

export const { updateStreamStatus } = broadcastSlice.actions

export const selectStreamFPS = (state: {
    broadcast: BroadcastState
}): number => {
    const { streamStatus } = state.broadcast
    return streamStatus?.data?.fps ?? 0
}

export default broadcastSlice.reducer
