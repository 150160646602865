import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../utils/myFetch"

interface FollowResponse {
    following: boolean
    notification_frequency: string
}

export const followApi = createApi({
    reducerPath: "followApi",
    baseQuery: cbBaseQuery({
        baseUrl: "/follow/",
    }),
    tagTypes: ["Follow"],
    endpoints: (builder) => ({
        isFollowing: builder.query<FollowResponse, string>({
            query: (username) => ({
                url: `is_following/${username}/`,
                method: "GET",
            }),
            providesTags: (result, error, username) => [
                { type: "Follow" as const, id: username },
            ],
        }),
        follow: builder.mutation<
            FollowResponse,
            { username: string; location: string }
        >({
            query: ({ username, location }) => ({
                url: `follow/${username}/`,
                method: "POST",
                data: {
                    location,
                },
            }),
            invalidatesTags: (result, error, { username }) => [
                { type: "Follow", id: username },
            ],
        }),
        unfollow: builder.mutation<
            FollowResponse,
            { username: string; location: string }
        >({
            query: ({ username, location }) => ({
                url: `unfollow/${username}/`,
                method: "POST",
                data: {
                    location,
                },
            }),
            invalidatesTags: (result, error, { username }) => [
                { type: "Follow", id: username },
            ],
        }),
    }),
})

export const { useIsFollowingQuery, useFollowMutation, useUnfollowMutation } =
    followApi
