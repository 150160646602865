import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

interface TypingState {
    [username: string]: boolean
}

const initialState: TypingState = {}

const userTypingSlice = createSlice({
    name: "userTyping",
    initialState,
    reducers: {
        setUserTyping: (
            state,
            action: PayloadAction<{ username: string; isTyping: boolean }>,
        ) => {
            state[action.payload.username] = action.payload.isTyping
        },
    },
})

export const { setUserTyping } = userTypingSlice.actions
export default userTypingSlice.reducer
