import { useEffect, useRef, useState } from "react"
import { t, Trans } from "@lingui/macro"
import { useAppContext } from "../../../../hooks/appContext"
import { Sizes, Weights } from "../../../../interfaces/sizing"
import Button from "../../atoms/Button"
import "./PrivateLowBalanceToast.scss"
import { Link } from "../../atoms/Link"
import { Typography } from "../../atoms/Typography"
import { fromSecToMinSec } from "./utils"

export const PrivateLowBalanceToast = ({
    openPuchasePage,
    closeToast,
    timeLeftInSec,
}: {
    openPuchasePage: ({
        source,
        roomType,
        target,
    }: {
        source: string
        roomType: string
        target: HTMLElement
    }) => void
    closeToast?: () => void
    timeLeftInSec: number
}) => {
    const buttonRef = useRef(null)
    const [timeLeft, setTimeLeft] = useState(timeLeftInSec)
    const userName = useAppContext()?.context?.logged_in_user?.username
    const isMobile = useAppContext()?.context?.is_mobile

    useEffect(() => {
        setTimeLeft(timeLeftInSec)
    }, [timeLeftInSec])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft === 0) {
                clearTimeout(timer)
                return
            }
            setTimeLeft(timeLeft - 1)
        }, 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [timeLeft])

    const timeToShow = fromSecToMinSec(timeLeft)
    return (
        <div className="PrivateLowBalanceToast">
            <div className="PrivateLowBalanceToast__info">
                <Typography
                    size={Sizes.xspx}
                    weight={Weights.bold}
                    className="PrivateLowBalanceToast__title"
                >
                    {t`Your tokens are running low`}
                </Typography>
                <Typography
                    size={Sizes.xspx}
                >{t`Show will end in ${timeToShow}`}</Typography>
            </div>
            <div className="PrivateLowBalanceToast__purchButtonWrapper">
                <Button
                    ref={buttonRef}
                    text={t`Add Tokens`}
                    className="PrivateLowBalanceToast__purchaseButton"
                    onClick={() => {
                        openPuchasePage({
                            source: "TokenSourceLowTokenBalanceNotifyPrivateShow",
                            roomType: "private",
                            target: buttonRef.current!,
                        })
                        if (closeToast) {
                            closeToast()
                        }
                    }}
                />
            </div>
            {!isMobile && (
                <Typography
                    size={Sizes.xspx}
                    className="PrivateLowBalanceToast__help"
                >
                    <Trans>
                        To activate auto refill, go to{" "}
                        <Link
                            href={`/p/${userName}/?tab=settings&from_autorefill_link=1`}
                            target="_blank"
                        >
                            settings
                        </Link>
                        .
                    </Trans>
                </Typography>
            )}
        </div>
    )
}
