import { RoomStatus } from "@multimediallc/web-utils"
import { createSlice } from "@reduxjs/toolkit"
import type { IBroadcasterPrivateStatus } from "../components/mobile_broadcast/types"
import type { PayloadAction } from "@reduxjs/toolkit"

export interface RoomState {
    roomStatus: RoomStatus
    roomSubject: string
    roomPrivateRequestStatus: IBroadcasterPrivateStatus | undefined
}

const initialState: RoomState = {
    roomStatus: RoomStatus.Unknown,
    roomSubject: "",
    roomPrivateRequestStatus: undefined,
}

export const roomSlice = createSlice({
    name: "room",
    initialState: initialState,
    reducers: {
        updateRoomStatus: (state, action: PayloadAction<RoomStatus>) => {
            state.roomStatus = action.payload
        },
        setRoomSubject: (state, action: PayloadAction<string>) => {
            state.roomSubject = action.payload
        },
        updateRoomPrivateRequestStatus: (
            state,
            action: PayloadAction<IBroadcasterPrivateStatus>,
        ) => {
            const oldState = state.roomPrivateRequestStatus
            const previousRequester = oldState?.requester ?? ""
            const { requester } = action.payload
            state.roomPrivateRequestStatus = {
                ...action.payload,
                requester:
                    requester && requester !== ""
                        ? requester
                        : previousRequester,
            }
        },
    },
})

export const {
    setRoomSubject,
    updateRoomStatus,
    updateRoomPrivateRequestStatus,
} = roomSlice.actions

export const selectRoomPrivateRequestStatus = (state: {
    room: RoomState
}): IBroadcasterPrivateStatus | undefined => state.room.roomPrivateRequestStatus

export const selectRoomStatus = (state: { room: RoomState }): RoomStatus =>
    state.room.roomStatus

export const selectRoomSubject = (state: { room: RoomState }): string =>
    state.room.roomSubject

export default roomSlice.reducer
