import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../utils/myFetch"

interface Account {
    username: string
}

interface SearchUsernameResponse {
    users: Account[]
}

export const accountsApi = createApi({
    reducerPath: "accountsApi",
    baseQuery: cbBaseQuery({
        baseUrl: "/api/ts/accounts/",
    }),
    endpoints: (builder) => ({
        searchByUsername: builder.query<
            SearchUsernameResponse,
            { term: string }
        >({
            query: ({ term }) => ({
                url: `username_search/?term=${term}`,
                method: "GET",
            }),
            keepUnusedDataFor: 5,
        }),
    }),
})

export const { useSearchByUsernameQuery } = accountsApi
