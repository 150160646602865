import type {
    ComponentPropsWithoutRef,
    CSSProperties,
    ElementType,
    Ref,
} from "react"
import { useLayoutEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { mergeClasses } from "../../../../utils/css"
import { DivotTooltip } from "../../molecules/DivotTooltip/DivotTooltip"
import { Typography } from "../Typography"
import type { DivotTooltipProps } from "../../molecules/DivotTooltip/DivotTooltip"
import "./FloatingTooltip.scss"

interface IFloatingTooltipProps extends ComponentPropsWithoutRef<"div"> {
    /**
     * Text to display in the tooltip
     */
    getParentBounds: () => DOMRect | undefined
    /**
     * Text to display in the tooltip
     */
    text?: string
    /**
     * Additional classes to provide to the inner div
     */
    className?: string
    /**
     * Whether to put a divot on the tooltip
     */
    useDivot?: boolean
    /**
     * Props to pass to the divot tooltip component
     */
    innerRef?: Ref<HTMLDivElement>
}

interface IFloatingtooltipPositions {
    tooltipTop: number
    tooltipLeft: number
    divotProps: DivotTooltipProps
}

/**
 * Helper function to shift tooltip horizontally or vertically to keep it on screen
 */
function keepTooltipOnScreen(
    tooltipTop: number,
    tooltipLeft: number,
    tooltipBox: DOMRect,
): { tooltipTop: number; tooltipLeft: number } {
    // Try to keep tooltip vertically on screen, prioritizing top of tooltip over bottom
    let newTop = tooltipTop
    let newLeft = tooltipLeft
    if (tooltipTop + tooltipBox.height > window.innerHeight) {
        newTop = window.innerHeight - tooltipBox.height
    }
    newTop = Math.max(0, newTop)
    if (tooltipLeft + tooltipBox.width > window.innerWidth) {
        newLeft = window.innerWidth - tooltipBox.width
    }
    newLeft = Math.max(0, newLeft)
    return { tooltipTop: newTop, tooltipLeft: newLeft }
}

/**
 * Helper function to position the tooltip relative to the parent element, with the divot pointing to the center of the
 * parent element. The tooltip is positioned with priority to the bottom, then top, then left, then right.
 */
function positionTooltip(
    parentBox: DOMRect,
    tooltipBox: DOMRect,
    useDivot: boolean,
): IFloatingtooltipPositions {
    let tooltipTop, tooltipLeft
    let divotProps = {} as DivotTooltipProps

    const divotSpace = 8
    const divotWidth = 14

    const tooltipMargin = useDivot ? 4 + divotSpace : 4
    // put tooltip below icon if there is enough space
    if (
        parentBox.bottom + tooltipMargin + tooltipBox.height <
        window.innerHeight
    ) {
        tooltipTop = parentBox.bottom + tooltipMargin
        tooltipLeft =
            parentBox.left + parentBox.width / 2 - tooltipBox.width / 2
        divotProps = {
            direction: "top",
            top: `${tooltipTop - divotSpace + 1}px`,
            left: `${parentBox.left + parentBox.width / 2 - divotWidth / 2}px`,
            divotBgTop: "1px",
            divotBgLeft: "1px",
        }
    }
    // put tooltip above icon if there is enough space
    else if (parentBox.top > tooltipMargin + tooltipBox.height) {
        tooltipTop = parentBox.top - tooltipMargin - tooltipBox.height
        tooltipLeft =
            parentBox.left + parentBox.width / 2 - tooltipBox.width / 2
        divotProps = {
            direction: "bottom",
            top: `${tooltipTop + tooltipBox.height - 1}px`,
            left: `${parentBox.left + parentBox.width / 2 - divotWidth / 2}px`,
            divotBgTop: "-1px",
            divotBgLeft: "1px",
        }
    }
    // put tooltip to the left of icon if there is enough space
    else if (parentBox.left > tooltipMargin + tooltipBox.width) {
        tooltipTop =
            parentBox.top + parentBox.height / 2 - tooltipBox.height / 2
        tooltipLeft = parentBox.left - tooltipMargin - tooltipBox.width
        divotProps = {
            direction: "right",
            top: `${parentBox.top + parentBox.height / 2 - divotWidth / 2}px`,
            left: `${tooltipLeft + tooltipBox.width - 1}px`,
            divotBgTop: "1px",
            divotBgLeft: "-1px",
        }
    }
    // put tooltip to the right of icon
    else {
        tooltipTop =
            parentBox.top + parentBox.height / 2 - tooltipBox.height / 2
        tooltipLeft = parentBox.left + parentBox.width + tooltipMargin
        divotProps = {
            direction: "left",
            top: `${parentBox.top + parentBox.height / 2 - divotWidth / 2}px`,
            left: `${tooltipLeft - divotSpace + 1}px`,
            divotBgTop: "1px",
            divotBgLeft: "1px",
        }
    }
    ;({ tooltipTop, tooltipLeft } = keepTooltipOnScreen(
        tooltipTop,
        tooltipLeft,
        tooltipBox,
    ))

    return {
        tooltipTop: tooltipTop,
        tooltipLeft: tooltipLeft,
        divotProps: divotProps,
    }
}

/**
 * A styled tooltip component. Should be used with `useTooltip` to trigger it from another element. Similar to
 * `Tooltip`, but is rendered as a child of the document body with a fixed position to be above all other elements.
 * This floating tooltip handles positioning itself and should not be manually positioned.
 *
 * @example
 * ```jsx
 * const ComponentWithTooltip = () => {
 *   const ref = useRef(null)
 *   const { isOpen } = useTooltip(ref)
 *
 *   return (
 *     <div style={{ position: "relative" }}>
 *       <div ref={ref}>I'll trigger a tooltip on hover</div>
 *       {isOpen && <FloatingTooltip text="I am some text that will display in the tooltip" />}
 *     </div>
 *   )
 * }
 * ```
 */
export const FloatingTooltip = ({
    getParentBounds,
    text,
    children = text,
    className,
    useDivot,
    ...props
}: IFloatingTooltipProps) => {
    const [tooltipPosition, setTooltipPosition] = useState(
        {} as IFloatingtooltipPositions,
    )
    const innerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const parentBox = getParentBounds()
        const tooltipBox = innerRef.current?.getBoundingClientRect()

        if (parentBox && tooltipBox) {
            setTooltipPosition(
                positionTooltip(parentBox, tooltipBox, useDivot ?? false),
            )
        }
    }, [getParentBounds, useDivot])

    return createPortal(
        <div className="cbr-floating-tooltip">
            <Typography
                size="xs"
                className={mergeClasses("cbr-tooltip", className)}
                role="tooltip"
                ref={innerRef as unknown as Ref<ElementType<"div">>} // need to do this bc of how ref types are handled
                style={
                    {
                        "--top": tooltipPosition.tooltipTop,
                        "--left": tooltipPosition.tooltipLeft,
                    } as CSSProperties
                }
                {...props}
            >
                {children}
            </Typography>
            {useDivot && <DivotTooltip {...tooltipPosition.divotProps} />}
        </div>,
        document.body,
    )
}
