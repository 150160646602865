import { mergeSortedArrays } from "@multimediallc/web-utils"
import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit"
import type { RootState } from "./store"
import type {
    IPrivateMessage,
    IRoomMessage,
    IRoomNotice,
    IShortcodeMessage,
    LogMessageType,
} from "@multimediallc/web-utils/types"
import type { EntityState, PayloadAction } from "@reduxjs/toolkit"

export enum ChatEntityType {
    RoomMessage,
    ShortcodeMessage,
    RoomNotice,
    PmMessage,
    LogMessage,
}

export type ChatEntityID = string | number

export type ChatEntityReduxMetadata = {
    id: ChatEntityID
    derivedTimestamp: number
    timeReceived: number
}

export type ReactRoomMessage = IRoomMessage & Partial<IShortcodeMessage>

function compareChatTimestamp(
    a: ChatEntityReduxMetadata,
    b: ChatEntityReduxMetadata,
): number {
    if (a.derivedTimestamp === b.derivedTimestamp) {
        if (a.timeReceived === b.timeReceived) {
            return 0
        }
        return a.timeReceived - b.timeReceived
    }
    return a.derivedTimestamp - b.derivedTimestamp
}

export type ReduxRoomMessage = ReactRoomMessage &
    ChatEntityReduxMetadata & { entityType: ChatEntityType.RoomMessage }
export type ReduxRoomNotice = IRoomNotice &
    ChatEntityReduxMetadata & { entityType: ChatEntityType.RoomNotice }
export type ReduxPm = IPrivateMessage &
    ChatEntityReduxMetadata & { entityType: ChatEntityType.PmMessage }
export type ReduxLogMessage = {
    message: LogMessageType
} & ChatEntityReduxMetadata & { entityType: ChatEntityType.LogMessage }

export type ReduxChatEntityData =
    | ReduxRoomMessage
    | ReduxRoomNotice
    | ReduxPm
    | ReduxLogMessage

const messagesAdapter = createEntityAdapter<ReduxRoomMessage>({
    sortComparer: compareChatTimestamp,
})

const noticesAdapter = createEntityAdapter<ReduxRoomNotice>({
    sortComparer: compareChatTimestamp,
})

const logAdapter = createEntityAdapter<ReduxLogMessage>({
    sortComparer: compareChatTimestamp,
})

const pmsAdapter = createEntityAdapter<ReduxPm>({
    sortComparer: compareChatTimestamp,
})

export interface ChatState {
    messages: EntityState<ReduxRoomMessage, ChatEntityID>
    notices: EntityState<ReduxRoomNotice, ChatEntityID>
    logs: EntityState<ReduxLogMessage, ChatEntityID>

    // NOTE - This PMs state is really only for mobile broadcast chat. When PMs are brought into Messaging,
    //        we will likely want that to be the single source of PMs truth, and broadcast chat will just
    //        keep an array that keys into Messaging's normalized PM storage
    pms: EntityState<ReduxPm, ChatEntityID>

    usernameToMessageEntityIDMap: { [username: string]: ChatEntityID[] }
    usernameToPmEntityIDMap: { [username: string]: ChatEntityID[] }
    latestTimestamp: number
    incingID: number
}

const initialState: ChatState = {
    messages: messagesAdapter.getInitialState(),
    notices: noticesAdapter.getInitialState(),
    logs: logAdapter.getInitialState(),
    pms: pmsAdapter.getInitialState(),
    usernameToMessageEntityIDMap: {},
    usernameToPmEntityIDMap: {},
    latestTimestamp: 0,
    incingID: 0,
}

export const chatSlice = createSlice({
    name: "chat",
    initialState: initialState,
    reducers: {
        newChatMessage: (
            state,
            { payload }: PayloadAction<ReactRoomMessage>,
        ) => {
            const derivedTimestamp = payload.ts ?? state.latestTimestamp
            const id = `message-${payload.messageID}`
            const newPayload: ReduxRoomMessage = {
                ...payload,
                id,
                derivedTimestamp: derivedTimestamp,
                timeReceived: Date.now(),
                entityType: ChatEntityType.RoomMessage,
            }

            messagesAdapter.addOne(state.messages, newPayload)

            const username = payload.fromUser.username
            if (state.usernameToMessageEntityIDMap[username] === undefined) {
                state.usernameToMessageEntityIDMap[username] = []
            }
            state.usernameToMessageEntityIDMap[payload.fromUser.username].push(
                id,
            )

            state.latestTimestamp = Math.max(
                state.latestTimestamp,
                derivedTimestamp,
            )
        },
        newChatNotice: (state, { payload }: PayloadAction<IRoomNotice>) => {
            const derivedTimestamp = payload.ts ?? state.latestTimestamp
            const newPayload: ReduxRoomNotice = {
                ...payload,
                id: `notice-${state.incingID}`,
                derivedTimestamp: derivedTimestamp,
                timeReceived: Date.now(),
                entityType: ChatEntityType.RoomNotice,
            }

            noticesAdapter.addOne(state.notices, newPayload)
            state.incingID += 1
            state.latestTimestamp = Math.max(
                state.latestTimestamp,
                derivedTimestamp,
            )
        },
        newLog: (state, { payload }: PayloadAction<LogMessageType>) => {
            const derivedTimestamp = state.latestTimestamp
            const newPayload: ReduxLogMessage = {
                message: payload,
                id: `log-${state.incingID}`,
                derivedTimestamp: derivedTimestamp,
                timeReceived: Date.now(),
                entityType: ChatEntityType.LogMessage,
            }

            logAdapter.addOne(state.logs, newPayload)
            state.incingID += 1
            state.latestTimestamp = Math.max(
                state.latestTimestamp,
                derivedTimestamp,
            )
        },
        newChatPm: (state, { payload }: PayloadAction<IPrivateMessage>) => {
            const derivedTimestamp =
                payload.ts ??
                payload.createdAt?.getTime() ??
                state.latestTimestamp
            const id = `pm-${payload.messageID}`
            const newPayload: ReduxPm = {
                ...payload,
                id,
                derivedTimestamp: derivedTimestamp,
                timeReceived: Date.now(),
                entityType: ChatEntityType.PmMessage,
            }

            pmsAdapter.addOne(state.pms, newPayload)

            const username = payload.fromUser.username
            if (state.usernameToPmEntityIDMap[username] === undefined) {
                state.usernameToPmEntityIDMap[username] = []
            }
            state.usernameToPmEntityIDMap[username].push(id)

            state.latestTimestamp = Math.max(
                state.latestTimestamp,
                derivedTimestamp,
            )
        },
        userChatDeleted: (
            state,
            { payload: username }: PayloadAction<string>,
        ) => {
            messagesAdapter.removeMany(
                state.messages,
                state.usernameToMessageEntityIDMap[username] ?? [],
            )
            pmsAdapter.removeMany(
                state.pms,
                state.usernameToPmEntityIDMap[username] ?? [],
            )
        },
    },
})

export const {
    newChatMessage,
    newChatNotice,
    newChatPm,
    newLog,
    userChatDeleted,
} = chatSlice.actions

export const {
    selectAll: selectAllChatMessages,
    selectById: selectChatMessageById,
} = messagesAdapter.getSelectors((state: RootState) => state.chat.messages)

export const {
    selectAll: selectAllChatNotices,
    selectById: selectChatNoticeById,
} = noticesAdapter.getSelectors((state: RootState) => state.chat.notices)

export const { selectAll: selectAllLogs, selectById: selectLogById } =
    logAdapter.getSelectors((state: RootState) => state.chat.logs)

export const { selectAll: selectAllChatPms, selectById: selectChatPmsById } =
    pmsAdapter.getSelectors((state: RootState) => state.chat.pms)

export const selectMobileBroadcastChat = createSelector(
    [
        selectAllChatMessages,
        selectAllChatNotices,
        selectAllLogs,
        selectAllChatPms,
    ],
    (messages, notices, logs, pms) => {
        return mergeSortedArrays(
            compareChatTimestamp,
            messages,
            notices,
            logs,
            pms,
        ) as ReduxChatEntityData[]
    },
)

export default chatSlice.reducer
