import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import type { UserChatSettings } from "../components/mobile_broadcast/types"
import type { PayloadAction } from "@reduxjs/toolkit"

interface User {
    username: string
    token_balance?: number
}

export interface UserState {
    languageCode: string
    loggedInUser?: User
    chatSettings?: UserChatSettings | undefined
}

const initialState: UserState = {
    languageCode: "en", // this should be overwritten by the preloadedState in store.ts
    chatSettings: undefined,
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateTokenBalance: (state, action: PayloadAction<number>) => {
            if (state.loggedInUser) {
                state.loggedInUser.token_balance = action.payload
            }
        },
        updateChatSettings: (
            state,
            action: PayloadAction<UserChatSettings>,
        ) => {
            state.chatSettings = action.payload
        },
    },
})

export const { updateTokenBalance, updateChatSettings } = userSlice.actions

export const selectTokenBalance = (state: RootState): number | undefined =>
    state.user.loggedInUser?.token_balance

export const selectChatSettings = (
    state: RootState,
): UserChatSettings | undefined => state.user.chatSettings

export default userSlice.reducer
