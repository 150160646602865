import type React from "react"
import "./CancelConfirmButtonPair.scss"
import { mergeClasses } from "../../../../utils/css"
import Button, { ButtonColor, ButtonSize } from "../../atoms/Button"
import { Flex } from "../../atoms/Flex"

export enum ButtonsFloatDirection {
    Right = "ButtonPairFloat-right",
    Left = "ButtonPairFloat-left",
}

interface CancelConfirmButtonPairProps {
    /**
     * Click Handler for the Cancel button.
     */
    cancelClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
    /**
     * Click Handler for the Call to Action button.
     */
    actionClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
    /**
     * Text for the action button.
     */
    actionText: string
    /**
     * Text for the cancel button.
     */
    cancelText: string
    /**
     * Color for the cancel button.
     */
    cancelColor?: ButtonColor
    /**
     * Color for the action button.
     */
    actionColor?: ButtonColor
    /**
     * A size to apply to both buttons
     */
    size?: ButtonSize
    /**
     * Which direction does the pairs float to
     */
    floatDirection?: ButtonsFloatDirection
    /**
     * Optional Testid for jest tests.
     */
    cancelTestId?: string
    /**
     * Optional Testid for jest tests.
     */
    actionTestId?: string

    cancelProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
    actionProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
}

/**
 * A bonded pair of a confirm and cancel button.
 */
export const CancelConfirmButtonPair = ({
    cancelClickHandler,
    actionClickHandler,
    actionText,
    cancelText,
    cancelTestId,
    actionTestId,
    floatDirection = ButtonsFloatDirection.Left,
    size = ButtonSize.Small,
    cancelColor = ButtonColor.Text,
    actionColor = ButtonColor.Orange,
    cancelProps,
    actionProps,
}: CancelConfirmButtonPairProps) => {
    const isFloatDirectionLeft = floatDirection === ButtonsFloatDirection.Left
    return (
        <Flex
            className={mergeClasses("CancelConfirmButtonPair", floatDirection)}
            align={isFloatDirectionLeft ? "start" : "end"}
            direction={isFloatDirectionLeft ? "row" : "row-reverse"}
        >
            <Button
                {...actionProps}
                className={mergeClasses("ActionButton", actionProps?.className)}
                onClick={actionClickHandler}
                text={actionText}
                data-testid={actionTestId}
                size={size}
                color={actionColor}
            />
            <Button
                {...cancelProps}
                className={mergeClasses("CancelButton", cancelProps?.className)}
                onClick={cancelClickHandler}
                text={cancelText}
                data-testid={cancelTestId}
                size={size}
                color={cancelColor}
            />
        </Flex>
    )
}
