import { useEffect, useState } from "react"
import { type ComponentPropsWithoutRef } from "react"
import "./AutoRefill.scss"
import { t, Trans } from "@lingui/macro"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { Button, ButtonColor, Link, Typography } from "../../common"
import { InputToggle } from "../../common/atoms/InputToggle"
import { useAutoRefillToggle } from "../useAutoRefillToggle"

interface AutoRefillProps extends ComponentPropsWithoutRef<"div"> {
    purchasedProduct: {
        product_id: string
        tokens: number
        cost: string
    }
}

export function AutoRefill({ purchasedProduct, ...props }: AutoRefillProps) {
    const userName = useAppContext()?.context?.logged_in_user?.username
    const [keepCurrentClicked, setKeepCurrentClicked] = useState(false)
    const [initialActiveAutorefill, setInitialActiveAutorefill] =
        useState<boolean>()
    const { autoRefillSettings, handleAutoRefillChange, isActive, isLoading } =
        useAutoRefillToggle({
            skip: false,
            toastContainer: "purchase-success-toast",
        })

    const isMobile = useAppContext()?.context?.is_mobile
    const textSize = isMobile ? Sizes.smpx : Sizes.xspx

    const extraProps = {
        isMobile,
        textSize,
    }

    useEffect(() => {
        if (isLoading || !autoRefillSettings) {
            return
        }
        if (initialActiveAutorefill === undefined) {
            const isActive =
                autoRefillSettings?.is_active &&
                autoRefillSettings?.plan?.product_id
            setInitialActiveAutorefill(Boolean(isActive))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillSettings])

    if (isLoading) {
        return null
    }

    const isSameProduct =
        autoRefillSettings?.plan?.product_id === purchasedProduct.product_id ||
        autoRefillSettings?.plan?.mobile_product_id ===
            purchasedProduct.product_id

    const currentAutoRefillTokenPackage = {
        tokens: autoRefillSettings?.plan?.tokens ?? 0,
        price: `$${autoRefillSettings?.plan?.cost ?? 0}`,
        productId: autoRefillSettings?.plan?.product_id ?? "",
    }

    const purchasedTokenPackage = {
        tokens: purchasedProduct.tokens ?? 0,
        price: purchasedProduct.cost ?? "0",
        productId: purchasedProduct.product_id,
    }

    const showChangedProduct = !isSameProduct && isActive && !keepCurrentClicked

    return (
        <div className="AutoRefill" {...props}>
            <div className="AutoRefill__header">
                <Typography weight={Weights.bold} size={Sizes.smpx}>
                    {t`Activate Auto Refill`}
                </Typography>
                <InputToggle
                    onToggle={(v) => {
                        if (initialActiveAutorefill) {
                            void handleAutoRefillChange(v)
                            return
                        }
                        void handleAutoRefillChange(
                            v,
                            purchasedProduct.product_id,
                        )
                    }}
                    checked={isActive}
                />
            </div>
            <Typography size={textSize} className="AutoRefill__middle">
                {showChangedProduct ? (
                    <MiddleAutoRefillSectionChangedProduct
                        currentPackage={currentAutoRefillTokenPackage}
                        newPackage={purchasedTokenPackage}
                        onChange={(val, productId) =>
                            void handleAutoRefillChange(val, productId)
                        }
                        onKeepCurrentClick={() => setKeepCurrentClicked(true)}
                        {...extraProps}
                    />
                ) : (
                    <MiddleAutoRefillSectionIfOff
                        numTokens={
                            initialActiveAutorefill
                                ? currentAutoRefillTokenPackage.tokens
                                : purchasedTokenPackage.tokens
                        }
                        price={
                            initialActiveAutorefill
                                ? (currentAutoRefillTokenPackage.price ?? "0")
                                : purchasedTokenPackage.price
                        }
                        userName={userName!}
                        minTokenThreshold={
                            initialActiveAutorefill
                                ? currentAutoRefillTokenPackage.tokens / 10
                                : purchasedTokenPackage.tokens / 10
                        }
                        keepCurrentClicked={keepCurrentClicked}
                        {...extraProps}
                    />
                )}
            </Typography>
            <div />
        </div>
    )
}

type MiddleAutoRefillSectionIfOffProps = {
    minTokenThreshold: number
    numTokens: number
    price: string
    userName: string
    keepCurrentClicked: boolean
    isMobile: boolean
    textSize: Sizes
}

const MiddleAutoRefillSectionIfOff = ({
    minTokenThreshold,
    numTokens,
    price,
    userName,
    keepCurrentClicked,
    isMobile,
    textSize,
}: MiddleAutoRefillSectionIfOffProps) => {
    return (
        <>
            {keepCurrentClicked && (
                <Typography size={textSize}>
                    No changes made to your auto refill.
                </Typography>
            )}

            <Trans>
                When your account balance falls below{" "}
                <Typography
                    component="span"
                    weight={Weights.bold}
                    size={textSize}
                >
                    {minTokenThreshold} tokens
                </Typography>
                , it will be refilled with{" "}
                <Typography
                    component="span"
                    weight={Weights.bold}
                    size={textSize}
                >
                    {`${numTokens} tokens - ${price}.`}
                </Typography>
                <Typography size={textSize} className="AutoRefill__last">
                    {isMobile ? (
                        "You can turn it off in the top-left menu."
                    ) : (
                        <>
                            You can manage it anytime in your{" "}
                            <Link
                                href={`/p/${userName}/?tab=settings&from_autorefill_link=1`}
                            >
                                account settings.
                            </Link>
                        </>
                    )}
                </Typography>
            </Trans>
        </>
    )
}

type MiddleAutoRefillSectionChangedProductProps = {
    currentPackage: {
        tokens: number
        price: string
        productId: string
    }
    newPackage: {
        tokens: number
        price: string
        productId: string
    }
    onKeepCurrentClick: () => void
    onChange: (val: boolean, productId: string) => void
    isMobile: boolean
    textSize: Sizes
}
const MiddleAutoRefillSectionChangedProduct = ({
    currentPackage,
    newPackage,
    onChange,
    onKeepCurrentClick,
    isMobile,
    textSize,
}: MiddleAutoRefillSectionChangedProductProps) => {
    const currentTokens = currentPackage.tokens
    const newTokens = newPackage.tokens
    const currentPrice = currentPackage.price
    const newPrice = newPackage.price

    return (
        <div className="AutoRefill__middleChanged">
            <Typography size={textSize}>
                You’ve purchased a different token amount. Update your auto
                refill package?
            </Typography>
            <div className="AutoRefill__packages">
                <Typography size={textSize}>
                    Current package:{" "}
                    <Typography
                        size={textSize}
                        weight={Weights.bold}
                        component="span"
                    >{`${currentTokens} tokens - ${currentPrice}`}</Typography>
                </Typography>
                <Typography size={textSize}>
                    New package:{" "}
                    <Typography
                        component="span"
                        size={textSize}
                        weight={Weights.bold}
                    >{`${newTokens} tokens - ${newPrice}`}</Typography>
                </Typography>
            </div>
            <div
                className={mergeClasses(
                    "AutoRefill__buttonsActions",
                    isMobile ? "mobile" : "",
                )}
            >
                <Button
                    text="No, keep current"
                    onClick={onKeepCurrentClick}
                    color={ButtonColor.Clear}
                />
                <Button
                    text="Yes, update"
                    onClick={() => onChange(true, newPackage.productId)}
                    color={ButtonColor.Orange}
                />
            </div>
        </div>
    )
}
