import { useEffect, useRef } from "react"

type ExceptionRef = React.RefObject<HTMLElement>

export const useOutsideClick = (
    callback: () => void,
    exceptionRefs: ExceptionRef[] = [],
) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            const target = event.target as Node
            const isClickedInsideMain =
                ref.current && ref.current.contains(target)
            const isClickedInsideException = exceptionRefs.some(
                (exceptionRef) =>
                    exceptionRef.current &&
                    exceptionRef.current.contains(target),
            )

            if (!isClickedInsideMain && !isClickedInsideException) {
                callback()
            }
        }

        document.addEventListener("mouseup", handleClickOutside)
        document.addEventListener("touchend", handleClickOutside)

        return () => {
            document.removeEventListener("mouseup", handleClickOutside)
            document.removeEventListener("touchend", handleClickOutside)
        }
    }, [callback, exceptionRefs])

    return ref
}
